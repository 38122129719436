import React, {FormEvent} from 'react';
import styles from './styles.module.css';

const password = 'gdg2025';

export default () => {
  const [passwordEnabled, setPasswordEnabled] = React.useState(
    !window.location.hostname.startsWith('localhost'),
  );
  const [value, setValue] = React.useState('');

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (value === password) {
      setPasswordEnabled(false);
    }
  };

  React.useEffect(() => {
    if (passwordEnabled) {
      document.body.style = 'overflow: hidden';
    } else {
      document.body.style = 'overflow: auto';
    }
  }, [passwordEnabled]);

  if (!passwordEnabled) return null;

  return (
    <form onSubmit={handleSubmit} className={styles.container}>
      <label htmlFor="password">This site is under development</label>
      <input
        id="password"
        placeholder="Enter the password"
        value={value}
        onChange={e => {
          setValue(e.currentTarget.value);
        }}
      />
      <button>Submit</button>
    </form>
  );
};
