import React from 'react';
import {createRoot} from 'react-dom/client';
import styles from './styles.module.css';
// import {RightItems} from './RightItems/index';
import Home from './Home/index';
import HamburgerSvg from './HamburgerSvg';
import Menu from './Menu';
import Search from './Search/index';
import RightItems from './RightItems';
import PasswordBanner from './PasswordBanner';

type Items = {name: string; link: string}[];

const Header = ({items}: {items: Items}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerInner}>
          <Home />
          <Search />
        </div>
        <div className={styles.headerInner}>
          <RightItems items={items} />
          {!open && (
            <HamburgerSvg
              onClick={() => {
                setOpen(!open);
              }}
            />
          )}
        </div>
      </div>
      <div className={styles.advertisement}>
        <b>Public consultation now open</b>
      </div>
      <Menu
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
      <PasswordBanner />
    </>
  );
};

export default (items: Items) => {
  const domNode = document.getElementById('header');
  if (domNode) {
    const root = createRoot(domNode);
    root.render(<Header items={items} />);
  }
};
