import {createRoot} from 'react-dom/client';
import styles from './styles.module.css';

const sections = document.getElementsByTagName('h2');

const Contents = () => {
  const sectionElements = [];
  for (let i = 0; i < sections.length; i++) {
    sectionElements.push(sections.item(i));
  }

  return (
    <div className={styles.container}>
      <b>Contents</b>
      <div className={styles.inner}>
        {sectionElements.map(section => (
          <a
            href={`#${section?.getAttribute('id')}`}
            key={section?.getAttribute('id')}
          >
            {section?.textContent}
          </a>
        ))}
      </div>
    </div>
  );
};

export default () => {
  const domNode = document.getElementById('contents');
  if (domNode) {
    const root = createRoot(domNode);
    root.render(<Contents />);
  }
};
