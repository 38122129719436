import styles from './styles.module.css';

const color = '#F7F5E6';

type Props = {
  onClick: () => void;
};

export default ({onClick}: Props) => (
  <button onClick={onClick} className={styles.hamburger}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      fill={color}
      className="bi bi-list"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
      />
    </svg>
  </button>
);
