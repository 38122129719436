export default [
  {
    keywords: 'squidward',
    rewrite: 'summary',
  },
].map(o => ({
  ...o,
  keywords: generateSubstrings(o.keywords),
}));

function generateSubstrings(word: string) {
  const substrings = [];
  for (let i = 1; i <= word.length; i++) {
    substrings.push(word.slice(0, i));
  }
  return substrings;
}
