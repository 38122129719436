import styles from './styles.module.css';

type Props = {
  className?: string;
};

export default ({className = ''}: Props) => {
  return (
    <a href="/" className={`${styles.container} ${className}`}>
      <img src="./images/cropped-dRx_logo_white.png" alt="dRx" />
    </a>
  );
};
