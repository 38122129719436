import {createRoot} from 'react-dom/client';
import memberPhotos from '../../member-photos.json';
import styles from './styles.module.css';

const topMembers = [
  'Quek, Amanda',
  'Page, A',
  'Etherton-Beer C',
  'Lee, Kenneth',
  'Reus, Xisco',
];

const Members = () => {
  const topPhotos: {name: string; filePath: string}[] = [];
  topMembers.forEach(name => {
    const memberIndex = memberPhotos.findIndex(o => o.name === name);
    if (memberIndex) {
      topPhotos.push(...memberPhotos.splice(memberIndex, 1));
    }
  });

  return (
    <div className={styles.container}>
      {topPhotos.map(o => (
        <div className={styles.item} key={o.name}>
          <div
            className={styles.itemImage}
            style={{
              backgroundImage: `url('/images/members/${o.filePath}')`,
            }}
          />
          <p>{o.name}</p>
        </div>
      ))}
      {memberPhotos.map(o => (
        <div className={styles.item} key={o.name}>
          <div
            className={styles.itemImage}
            style={{
              backgroundImage: `url('/images/members/${o.filePath}')`,
            }}
          />
          <p>{o.name}</p>
        </div>
      ))}
    </div>
  );
};

export default () => {
  const domNode = document.getElementById('members');
  if (domNode) {
    const root = createRoot(domNode);
    root.render(<Members />);
  }
};
