import header from './header';
import contents from './contents';
import footer from './footer';
import members from './members';

const items = [
  {
    name: 'Guideline Development Group',
    link: '/members.html',
  },
  {
    name: 'Summary of Recommendations',
    link: 'https://google.com',
  },
  {
    name: 'Resource Library',
    link: 'https://google.com',
  },
  {
    name: 'Technical Report',
    link: 'https://google.com',
  },
  {
    name: 'Administrative Report',
    link: 'https://google.com',
  },
];

header(items);
contents();
footer(items);
members();
