import React from 'react';
import styles from './styles.module.css';

type Items = {name: string; link: string}[];

export default ({items}: {items: Items}) => {
  return (
    <div className={styles.container}>
      {items.map((item, ind) => (
        <React.Fragment key={item.name}>
          <span className={styles.pipe}>{ind !== 0 && '|'}</span>
          <a href={item.link} target="_blank" className={styles.item}>
            {item.name}
          </a>
        </React.Fragment>
      ))}
    </div>
  );
};
