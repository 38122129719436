import styles from './styles.module.css';
import pageContent from '../../../page-content.json';
import Home from '../Home';

type Props = {
  open: boolean;
  onClose: () => void;
};

export default ({open, onClose}: Props) => {
  return (
    <div
      className={`${styles.container} ${open ? '' : styles.containerClosed}`}
    >
      <div className={styles.header}>
        <button onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="100%"
            fill="#F7F5E6"
            className="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </button>
      </div>
      <div className={styles.content}>
        {pageContent.map(page => (
          <a href={page.link} className={styles.menuItem} key={page.link}>
            {page.name}
          </a>
        ))}
        <Home className={styles.homeIcon} />
      </div>
    </div>
  );
};
